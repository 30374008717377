import {createRouter, createWebHistory} from 'vue-router';
import store from "@/store";
import HomeView from '../views/HomeView.vue';
import ContractListView from '../views/ContractListView.vue';
import UserListView from '../views/UserListView.vue';
import ContractCreateView from '../views/ContractCreateView.vue';
import ActListView from "@/views/ActListView.vue";
import TaskListView from "@/views/TaskListView.vue";
import TaskCreateView from "@/views/TaskCreateView.vue";
import StaffTimelineView from "@/views/StaffTimelineView.vue";
import OrgStructureView from "@/views/OrgStructureView.vue";
import AlertListView from "@/views/AlertListView.vue";
import SearchView from "@/views/SearchView.vue";
import SettingsView from "@/views/SettingsView.vue";
import ProfileView from "@/views/ProfileView.vue";
import SettingsACLView from "@/views/SettingsACLView.vue";
import TaskDetailView from "@/views/TaskDetailView.vue";
import ContractTaskListView from "@/views/ContractTaskListView.vue";
import ContractDetailView from "@/views/ContractDetailView.vue";
import UserTaskListView from "@/views/UserTaskListView.vue";
import UserDetailView from "@/views/UserDetailView.vue";
import ProfileChangePasswordView from "@/views/ProfileChangePasswordView.vue";
import LoginView from "@/views/LoginView.vue";
import LogoutView from "@/views/LogoutView.vue";
// import {store} from "core-js/internals/reflect-metadata";

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
    },
    {
        path: '/contracts',
        name: 'ContractListView',
        component: ContractListView,
    },
    {
        path: '/contracts/create',
        name: 'ContractCreateView',
        component: ContractCreateView,
    },
    {
        path: '/contracts/:id/tasks',
        name: 'ContractTaskListView',
        component: ContractTaskListView,
    },
    {
        path: '/acts',
        name: 'ActListView',
        component: ActListView,
    },
    {
        path: '/users',
        name: 'UserListView',
        component: UserListView,
    },
    {
        path: '/users/detail/:id',
        name: 'UserDetailView',
        component: UserDetailView,
    },
    {
        path: '/staffs/timeline',
        name: 'StaffTimelineView',
        component: StaffTimelineView,
    },
    {
        path: '/tasks',
        name: 'TaskListView',
        component: TaskListView,
    },
    {
        path: '/tasks/create',
        name: 'TaskCreateView',
        component: TaskCreateView,
    },
    {
        path: '/tasks/detail/:id',
        name: 'TaskDetailView',
        component: TaskDetailView,
    },
    {
        path: '/staffs/org-structure',
        name: 'OrgStructureView',
        component: OrgStructureView,
    },
    {
        path: '/users/:id/tasks',
        name: 'UserTaskListView',
        component: UserTaskListView,
    },
    {
        path: '/contract/detail/:id',
        name: 'ContractDetailView',
        component: ContractDetailView,
    },
    {
        path: '/alerts',
        name: 'AlertListView',
        component: AlertListView,
    },
    {
        path: '/search',
        name: 'SearchView',
        component: SearchView,
    },
    {
        path: '/settings',
        name: 'SettingsView',
        component: SettingsView,
    },
    {
        path: '/settings/acl',
        name: 'SettingsACLView',
        component: SettingsACLView,
    },
    {
        path: '/profile',
        name: 'ProfileView',
        component: ProfileView,
    },
    {
        path: '/profile/change-password',
        name: 'ProfileChangePasswordView',
        component: ProfileChangePasswordView,
    },
    {
        path: '/login',
        name: 'LoginView',
        component: LoginView,
        meta: {
            authAllowAny: true,
        },
    },
    {
        path: '/logout',
        name: 'LogoutView',
        component: LogoutView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => !record.meta.authAllowAny)) {
        if (!store.state.userToken) {
            next({
                path: "/login",
                params: {nextUrl: to.fullPath},
            });
        } else {
            if (!store.state.isAuthenticated) {
                next({
                    path: "/login",
                    params: {nextUrl: to.fullPath},
                });
            } else {
                next();
            }
        }
    } else {
        next();
    }
});


export default router;
