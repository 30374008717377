<template>
  <NavBar :title="'Загрузка сотрудников на проектах'"></NavBar>
  <div>
    <div>
      <HelpmateTimeline :events="events" />
    </div>
  </div>
</template>

<script>
import HelpmateTimeline from "@/components/HelpmateTimeline.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'StaffTimelineView',
  components: {
    NavBar,
    HelpmateTimeline,
  },
  data() {
    return {
      events: [
        { id: 1, resource: 'Event 1', start_date: '2023-01-01', end_date: '2023-01-05' },
        { id: 2, resource: 'Event 2', start_date: '2023-01-06', end_date: '2023-01-10' },
        { id: 3, resource: 'Event 3', start_date: '2023-01-11', end_date: '2023-01-15' },
        { id: 4, resource: 'Event 3', start_date: '2023-01-01', end_date: '2023-01-04' },
      ],
    };
  },
};
</script>