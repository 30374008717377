<script setup>
import TaskListComponent from "@/components/Tasks/Task/TaskListComponent.vue";
import ContractDataService from "@/services/ContractDataService";
</script>

<template>
  <NavBar :title="'Задачи'" :breadcrumbs="[{'url': '/contracts', 'title': 'Договоры'}, {'url': `/contract/detail/${contractId}`, 'title': `${data.code ? data.code : '- Шифр не указан -'}`}]"></NavBar>

    <h5>Шифр Договора: {{ data.code }}</h5>
    <h5>Наименование Договора: {{ data.title }}</h5>

    <TaskListComponent :tableContext="tableContext" :contractId="contractId"></TaskListComponent>

</template>

<script>

import NavBar from "@/components/NavBar.vue";

export default {
  name: 'ContractTaskListView',
  components: {NavBar, TaskListComponent},
  data() {
    return {
      tableContext: {
        contractId: Number(this.$route.params.id),
        baseFilter: {
          contract_id: Number(this.$route.params.id),
        }
      },
      data: {},
      isLoading: false,
    }
  },
  methods: {
    updateData() {
      this.isLoading = true
      // console.log('this.contractId', this.contractId)
      ContractDataService.getById(this.contractId)
        .then((response) => {
          console.log(response);
          this.data = { ...response.data }
          this.data.user_autocomplete_object = { id: this.data.user, title: this.data.user_object.email }
          // Object.keys(this.data).forEach((key) => {
          //   this.data[key] = formatPriceInt(this.data[key]);
          // });
          this.isLoading = false
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
  computed: {
    contractId() {
      return Number(this.$route.params.id);
    }
  },
  mounted() {
    this.tableContext.contractId = this.contractId
    this.tableContext.baseFilter.contract_id = this.contractId
    this.updateData()
  }
};
</script>
