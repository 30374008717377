<template>
  <NavBar :title="'Задачи'">
    <RouterLink class="btn btn-outline-success" to="/tasks/create">+</RouterLink>
  </NavBar>

  <div class="container">
    <TaskListComponent></TaskListComponent>
  </div>

</template>

<script>

import NavBar from "@/components/NavBar.vue";
import TaskListComponent from "@/components/Tasks/Task/TaskListComponent.vue";

export default {
  name: 'TaskListView',
  components: {TaskListComponent, NavBar},
};
</script>
