import AbstractDataService from "@/helpmate/services/AbstractDataService";
import http from "@/helpmate/services/http-common";


class ActDataService extends AbstractDataService {

    constructor() {
        super('/legal-documents/act');
    }

    createEmpty(tableContext={}) {
        return http.post(`${this.api_url}/`, {
            title: '',
            contract: tableContext.contractId,
        });
    }

}

export default new ActDataService();
