import AbstractDataService from "@/helpmate/services/AbstractDataService";


class WorklogDataService extends AbstractDataService {

    constructor() {
        super('/tasks/worklog');
    }


}

export default new WorklogDataService();
