import AbstractDataService from "@/helpmate/services/AbstractDataService";


class CommentDataService extends AbstractDataService {

    constructor() {
        super('/tasks/comment');
    }


}

export default new CommentDataService();
