<template>
  <div class="chart-container">
    <div class="chart-container-inner" ref="svgElementContainer"></div>
  </div>
</template>

<script>
import {OrgChart as BaseOrgChart} from 'd3-org-chart';

export default {
  name: 'OrgChart',
  props: ['data'],
  data() {
    return {
      chartReference: null
    };
  },
  watch: {
    data(value) {
      this.renderChart(value);
    }
  },
  created() {
  },
  methods: {
    renderChart(data) {
      if (!this.chartReference) {
        this.chartReference = new BaseOrgChart();
      }
      this.chartReference
          .container(this.$refs.svgElementContainer) // node or css selector
          .data(data)
          .nodeWidth(() => 150)
          .nodeHeight(() => 50)
          .nodeContent((node) => {
            return node.data.title;
          })
          .onNodeClick(d => console.log(d + ' node clicked'))
          .render()
          .expandAll()
          .fit();
    }
  }
};
</script>

<style>
.chart-container {
  display: flex;
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  height: 100vh; /* Высота контейнера */
  width: 100%;
}

.chart-container-inner {
  text-align: center;
  width: 100%; /* Ширина по умолчанию */
  height: auto; /* Высота по умолчанию */
  overflow: hidden; /* Скрыть переполнение */
}

.node-foreign-object-div {
  display: flex;
  background: #dadada;
  border: 1px solid black;
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
}
</style>