import http from "@/helpmate/services/http-common";


class ContractTotalBaseDataService {

    getData({contractId}) {
        return http.get(`/total_cash_base/`, {params: {
            contract_id: contractId,
        }});
    }

}

export default new ContractTotalBaseDataService();
