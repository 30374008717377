import http from "@/helpmate/services/http-common";
import {UniqueString} from 'unique-string-generator';
import AbstractDataService from "@/helpmate/services/AbstractDataService";

class UserDataService extends AbstractDataService {

    constructor() {
        super('/users/user');
    }

    createEmpty() {
        return http.post(`${this.api_url}/`, {
            'username': UniqueString(),
        });
    }

    // ---

    getProfile() {
        return http.get(`${this.api_url}/profile/`);
    }

    updateProfile(data) {
        return http.post(`${this.api_url}/update_profile/`, data);
    }

    //
    // get(id) {
    //   return http.get(`/tutorials/${id}`);
    // }
    //
    // create(data) {
    //   return http.post("/tutorials", data);
    // }
    //
    // update(id, data) {
    //   return http.put(`/tutorials/${id}`, data);
    // }
    //
    // delete(id) {
    //   return http.delete(`/tutorials/${id}`);
    // }
    //
    // deleteAll() {
    //   return http.delete(`/tutorials`);
    // }
    //
    // findByTitle(title) {
    //   return http.get(`/tutorials?title=${title}`);
    // }
}

export default new UserDataService();
