<template>
  <NavBar :title="'Сотрудники'"></NavBar>

  <div class="container">
    <UserListComponent></UserListComponent>
  </div>



  <!--    <table class="table">-->
  <!--      <thead>-->
  <!--      <tr>-->
  <!--        <th>ФИО</th>-->
  <!--        <th>Должность</th>-->
  <!--        <th>Подразделение</th>-->
  <!--        <th>Зарплата в месяц</th>-->
  <!--        <th>Дата принятия на работу</th>-->
  <!--        <th>История изменения зарплаты</th>-->
  <!--        <th>Управление</th>-->
  <!--      </tr>-->
  <!--      </thead>-->
  <!--    </table>-->
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import UserListComponent from "@/components/Users/User/UserListComponent.vue";

export default {
  name: 'UserListView',
  components: {
    NavBar,
    UserListComponent
  },
};
</script>
