<script setup>
import SearchDataService from '@/services/SearchDataService';
</script>


<template>
  <NavBar :title="'Поиск'"></NavBar>

  <div class="container mt-4">
    <div class="row">
      <div class="col">
        <h2>Результаты поиска</h2>
        <div v-if="objects.length === 0" class="alert alert-info" role="alert">
          Нет результатов для вашего запроса.
        </div>
        <div v-else>
          <ul class="list-group">
            <li v-for="object in objects" :key="object.id" class="list-group-item">
              <div style="text-align: right; float: right;">
                <small><span class="text-muted">{{ object.type_text }}</span></small>
              </div>
              <h5>
                <RouterLink :to="object.url">{{ object.title }}</RouterLink>
              </h5>
              <p v-if="object.type_text !== 'Сотрудник'">Договор: {{ object.contract_code }} | {{ object.contract_title }}</p>
              <a :href="object.url" class="btn btn-primary">Подробнее</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <nav aria-label="Page navigation" class="mt-4" v-if="totalPages > 1">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: Number(page) === 1 }">
        <a class="page-link" href="#" @click.prevent="handlePageClick(Number(page) - 1)" aria-label="Previous">
          <span aria-hidden="true">&larr;</span>
        </a>
      </li>
      <li v-if="Math.min(...navPages) > 1" class="page-item">
        <span class="page-link">...</span>
      </li>
      <li v-for="n in navPages" :key="n" class="page-item" :class="{ active: n === Number(page) }">
        <a class="page-link" href="#" @click.prevent="handlePageClick(n)">{{ n }}</a>
      </li>
      <li v-if="totalPages > Math.max(...navPages)" class="page-item">
        <span class="page-link">...</span>
      </li>
      <li class="page-item" :class="{ disabled: Number(page) === totalPages }">
        <a class="page-link" href="#" @click.prevent="handlePageClick(Number(page) + 1)" aria-label="Next">
          <span aria-hidden="true">&rarr;</span>
        </a>
      </li>
    </ul>
  </nav>


</template>

<script>

import NavBar from "@/components/NavBar.vue";


export default {
  name: 'SearchView',
  components: { NavBar },
  data() {
    return {
      objects: [],
      totalPages: 1,
    }
  },
  computed: {
    q() {
      return this.$route.query.q || '';
    },
    page() {
      return this.$route.query.page || 1;
    },
    navPages() {
      return Array.from({ length: Math.min(5, this.totalPages) }, (v, i) => {
        const pageNum = this.page - 2 + i;
        return pageNum > 0 && pageNum <= this.totalPages ? pageNum : null;
      }).filter(n => n);
    }
  },
  methods: {
    handlePageClick(page) {
      if (page > 0 && page <= this.totalPages) {
        this.$router.push({ query: { q: this.q, page } });
      }
    },
    updateData() {
      SearchDataService.search({
        q: this.q,
        page: this.page,
      })
        .then((response) => {
          this.objects = response.data.results;
          this.totalPages = response.data.total_pages;
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.updateData();
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        this.updateData();
      }
    },
    page() {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('page', this.page);
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      this.updateData();
    }
  }
};
</script>
