import http from "./http-common";

class AbstractDataService {
    constructor(api_url='/namespace/model') {
        this.api_url = api_url
    }

    getAll(filters) { // deprecated
        const params = { ...filters}
        return http.get(`${this.api_url}/`, {params: params});
    }

    getList(filters) {
        const params = { ...filters}
        return http.get(`${this.api_url}/`, {params: params});
    }

    getById(id) {
        return http.get(`${this.api_url}/${id}/`);
    }

    patch(id, data) {
        return http.patch(`${this.api_url}/${id}/`, data);
    }

    create(data) {
        return http.post(`${this.api_url}/`, data);
    }

    createEmpty() {
        return http.post(`${this.api_url}/`, {});
    }

    createClone(id) {
        return http.post(`${this.api_url}/${id}/clone/`, {});
    }

    delete(id) {
        return http.delete(`${this.api_url}/${id}/`);
    }

    deleteList(ids) {
        return http.post(`${this.api_url}/delete_list/`, ids);
    }

    autocomplete(search_query) {
        return http.post(`${this.api_url}/autocomplete/`, {search_query: search_query});
    }

}

export default AbstractDataService;
