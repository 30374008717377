import http from "@/helpmate/services/http-common";

class SearchDataService {

    search(data) {
        return http.post("/search/", data);
    }
}

export default new SearchDataService();
