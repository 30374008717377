<template>

  <HelpmateTable :tableContext="tableContext" :dataService="getDataService()" :columns="columns"></HelpmateTable>

</template>

<script>
import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";
import SubcontractDataService from "@/services/SubcontractDataService";

export default {
  name: 'SubcontractListComponent',
  components: {
    HelpmateTable,
  },
  props: ['contractId'],
  data() {
    return {
      tableContext: {
        contractId: 0,
        baseFilter: { // для обязательной фильтрации таблицы
          contract_id: this.contractId,
        }
      },
      columns: [
        // {field: 'id', fieldType: 'link', title: 'Подробнее', linkTemplate: '/contract/detail/#ID#', readonly: true},
        {field: 'title', fieldType: 'text', title: 'Наименование'},
        {field: 'amount', fieldType: 'priceint', title: 'Стоимость, руб.', aggregate: 'sum'},
        {field: 'comment', fieldType: 'text', title: 'Примечание'},
        {field: 'date_at', fieldType: 'date', title: 'Дата'},
      ],
    }
  },
  methods: {
    getDataService() {
      return SubcontractDataService
    },
  },
  mounted() {
    this.tableContext.contractId = this.contractId
  }
};
</script>
