import AbstractDataService from "@/helpmate/services/AbstractDataService";
import http from "@/helpmate/services/http-common";
class TaskDataService extends AbstractDataService {

    constructor() {
        super('/tasks/task');
    }

    updateStatus(id, status) {
        return http.post(`${this.api_url}/${id}/update_status/`, { status });
    }

    createEmpty(tableContext={}) {
        return http.post(`${this.api_url}/`, {
            title: '',
            contract: tableContext.contractId ? tableContext.contractId : null,
            user_contractor: tableContext.userContractorId ? tableContext.userContractorId : null,
        });
    }
}

export default new TaskDataService();
