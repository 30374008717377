<template>
    <NavBar :title="'Дашборд'"></NavBar>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'HomeView',
  components: {NavBar},
};
</script>
