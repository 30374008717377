<script setup>
import {ref, onMounted} from 'vue';

// const props = defineProps(['customClass']);
let tabContainer = ref(null);
let tabHeaders = ref(null);
let tabs = ref(null);
let activeTabIndex = ref(0);

onMounted(() => {
  tabs.value = [...tabContainer.value.querySelectorAll('.tab')];
  for (let x of tabs.value) {
    if (x.classList.contains('active')) {
      activeTabIndex = tabs.value.indexOf(x);
    }
  }

  // hack
  setTimeout(() => {
    changeTab(0)
  }, 1)
})
const changeTab = (index) => {
  activeTabIndex = index;
  for (let x of [...tabs.value, ...tabHeaders.value]) {
    x.classList.remove('active')
  }
  tabs.value[activeTabIndex].classList.add('active')
  tabHeaders.value[activeTabIndex].classList.add('active')
}
</script>

<template>
  <div id="tabs-container" ref="tabContainer">
    <div id="tab-headers">
      <ul class="nav nav-underline mb-3">
        <!-- this shows all of the titles -->
        <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
          <a class="nav-link" @click="changeTab(index)" ref="tabHeaders" :class="activeTabIndex == index ? 'active' : ''" aria-current="page" href="#">{{ tab.title }}</a>
        </li>
      </ul>
    </div>
    <!-- this is where the tabs go, in this slot -->
    <div id="active-tab">
      <slot></slot>
    </div>
  </div>
</template>
