import http from "@/helpmate/services/http-common";


class ContractTotalFactDataService {

    getData({contractId}) {
        return http.get(`/total_cash/`, {params: {
            contract_id: contractId,
        }});
    }

}

export default new ContractTotalFactDataService();
