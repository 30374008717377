<script setup>
import HelpmateSpinner from "@/helpmate/components/HelpmateSpinner.vue";

</script>

<template>

  <HelpmateSpinner :isLoading="isLoading"></HelpmateSpinner>

  <div v-if="!isLoading">
    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>Наименование</th>
        <th>Стоимость, руб.</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>Сумма доп. соглашений</td>
        <td>{{ data.total_ds }}</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Итого по Договору с ДС</td>
        <td>{{ data.total_contract_and_ds }}</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Стоимость субподрядных работ</td>
        <td>{{ data.total_subcontract }}</td>
      </tr>
      <tr>
        <td>4</td>
        <td>Дополнительные расходы</td>
        <td>{{ data.total_additional_expenses }}</td>
      </tr>
      <tr>
        <td>5</td>
        <td>Наши деньги (с учетом ДС за вычетом суб. и доп. расх.)</td>
        <td>{{ data.total_our_cash }}</td>
      </tr>
      <tr>
        <td>6</td>
        <td>Фактические расходы</td>
        <td>{{ data.total_fact_expenses }}</td>
      </tr>
      <tr>
        <td>7</td>
        <td>Расчетная требуемая стоимость</td>
        <td>{{ data.total_treb_cost }}</td>
      </tr>
      <tr>
        <td>8</td>
        <td>Стоимость заактированных работ</td>
        <td>{{ data.total_act }}</td>
      </tr>
      <tr>
        <td>9</td>
        <td>Остаток по Договору</td>
        <td>{{ data.total_remaining }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>


import ContractTotalBaseDataService from "@/services/ContractTotalBaseDataService";
import {formatPriceInt} from "@/helpmate/utils";

export default {
  name: 'ContractTotalBase',
  props: ['contractId'],
  components: [
    // HelpmateSpinner
  ],
  data() {
    return {
      isLoading: true,
      data: {
        total_ds: 0,  // Сумма доп. соглашений
        total_contract_and_ds: 0,  // Итого по Договору с ДС
        total_subcontract: 0,  // Стоимость субподрядных работ
        total_additional_expenses: 0,  // Дополнительные расходы
        total_our_cash: 0,  // Наши деньги (с учетом ДС за вычетом суб. и доп. расх.)
        total_fact_expenses: 0,  // Фактические расходы
        total_treb_cost: 0,  // Расчетная требуемая стоимость
        total_act: 0,  // Стоимость заактированных работ
        total_remaining: 0,  // Остаток по Договору
      }
    }
  },
  methods: {
    updateData(changeLoading = true) {
      this.isLoading = changeLoading ? true : this.isLoading
      // console.log('this.contractId', this.contractId)
      ContractTotalBaseDataService.getData({contractId: this.contractId})
          .then((response) => {
            console.log(response);
            this.data = {...response.data}
            Object.keys(this.data).forEach((key) => {
              this.data[key] = formatPriceInt(this.data[key]);
            });
            this.isLoading = false
          })
          .catch(e => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.updateData()
    setInterval(() => {
      this.updateData(false)
    }, 3000)
  },
}
</script>


<style scoped>

</style>