<script setup>
import ContractDataService from "@/services/ContractDataService";
import UserDataService from "@/services/UserDataService";
import HelpmateAutocomplete from "@/helpmate/components/HelpmateAutocomplete.vue";
</script>

<template>
  <NavBar :title="'Новый договор'"
    :breadcrumbs="[{ 'url': { name: 'ContractListView', params: {} }, 'title': 'Договоры' }]"></NavBar>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <form @submit.prevent="createContract">
          <div class="form-group">
            <label for="contractCode">Шифр Договора (титула)</label>
            <input type="text" v-model="data.code" class="form-control" id="contractCode">
          </div>
          <div class="form-group">
            <label for="contractTitle">Наименование Договора (титула)</label>
            <input type="text" v-model="data.title" class="form-control" id="contractTitle">
          </div>
          <div class="form-group">
            <label for="contractAmount">Стоимость Договора</label>
            <input type="number" v-model="data.amount" class="form-control" id="contractAmount">
          </div>

          <div class="form-group">
            <label for="contractUser">Руководитель проекта</label>
            <HelpmateAutocomplete :autofocus="true" @onselect="handleUserSelect" :id="`contractUser`"
              :value="data.user_autocomplete_object" :dataService="UserDataService"></HelpmateAutocomplete>
          </div>
          <div class="form-group">
            <label for="contractEndAt">Дата окончания</label>
            <input type="date" v-model="data.end_at" class="form-control" id="contractEndAt">
          </div>

          <button type="submit" class="btn btn-primary mt-3">Сохранить</button>
        </form>
      </div>
    </div>



  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'ContractCreateView',
  components: { NavBar },
  data() {
    return {
      isLoading: true,
      data: {
        code: '',  // Шифр Договора (титула)
        title: '',  // Наименование Договора (титула)
        amount: 0,  // Стоимость Договора
        user: null,  // Руководитель проекта
        end_at: null,  // Дата окончания
        user_autocomplete_object: { id: null, title: '' }
      }
    }
  },
  methods: {
    createContract() {
      ContractDataService.create({
        code: this.data.code,
        title: this.data.title,
        amount: this.data.amount,
        user: this.data.user,
        user_object: this.data.user_object,
        end_at: this.data.end_at,
      })
        .then(response => {
          // console.log(response.data)
          this.data = response.data
          this.data.user_autocomplete_object = { id: this.data.user, title: this.data.user_object.email }
          this.$store.commit('addToast', { title: "Сохранено", message: "Данные договора сохранены" })
          this.$router.push({ name: 'ContractDetailView', params: { id: response.data.id } });
        })
        .catch(e => {
          console.log(e);
          this.$store.commit('addToast', { title: "Ошибка!", message: "Не сохранено" })
        });
    },
    handleUserSelect(option) {
      this.data.user = option.id
    }
  },
};
</script>
