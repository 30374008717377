import AbstractDataService from "@/helpmate/services/AbstractDataService";


class ContractDataService extends AbstractDataService {

  constructor() {
        super('/legal-documents/contract');
    }

}

export default new ContractDataService();
