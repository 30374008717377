<script setup>
import TaskDataService from "@/services/TaskDataService";
import CommentDataService from "@/services/CommentDataService";
import WorklogDataService from "@/services/WorklogDataService";
import { formatPriceInt, formatDate, formatDateTime } from "@/helpmate/utils";
import moment from "moment";
</script>

<template>
  <NavBar :title="`${data.code} - ${data.title}`"
    :breadcrumbs="[{ 'url': { name: 'TaskListView', params: {} }, 'title': 'Задачи' }]">
  </NavBar>
  <div class="container">

    <h3>{{ data.title }}</h3>

    <div class="row">
      <div class="col-md-8">
        <h5>Детали задачи</h5>
        <p><span class="text-muted">Шифр:</span> {{ data.code }}</p>
        <p><span class="text-muted">Статус:</span> <span :class="{
          'badge text-bg-secondary': data.status === STATUS_NEW,
          'badge text-bg-warning': data.status === STATUS_IN_PROGRESS,
          'badge text-bg-success': data.status === STATUS_COMPLETED,
          'badge text-bg-dark': data.status === STATUS_CLOSED || data.status === STATUS_CANCELLED,
          'badge text-bg-danger': data.status === STATUS_PAUSED
        }">{{ data.status_text }}</span></p>

        <div class="btn-group" role="group" aria-label="Task Actions">
          <button v-if="data.available_transitions.includes(STATUS_NEW)" type="button" class="btn btn-light"
            @click="reopenTask"><i class="bi-arrow-clockwise"></i> Переоткрыть</button>
          <button v-if="data.available_transitions.includes(STATUS_IN_PROGRESS)" type="button" class="btn btn-light"
            @click="executeTask"><i class="bi-play"></i> Выполняю</button>
          <button v-if="data.available_transitions.includes(STATUS_COMPLETED)" type="button" class="btn btn-light"
            @click="completeTask"><i class="bi-check"></i> Выполнено</button>
          <button v-if="data.available_transitions.includes(STATUS_CANCELLED)" type="button" class="btn btn-light"
            @click="cancelTask"><i class="bi-x"></i> Отменить</button>
          <button v-if="data.available_transitions.includes(STATUS_CLOSED)" type="button" class="btn btn-light"
            @click="closeTask"><i class="bi-flag"></i> Закрыть</button>
          <button v-if="data.available_transitions.includes(STATUS_PAUSED)" type="button" class="btn btn-light"
            @click="pauseTask"><i class="bi-pause"></i> Приостановить</button>
        </div>

        <h5>Описание</h5>
        <p>{{ data.content || 'Описание отсутствует' }}</p>

        <h5 v-if="data.content_comment">Примечание</h5>
        <p v-if="data.content_comment">{{ data.content_comment }}</p>


      </div>
      <div class="col-md-4">
        <h5>Люди</h5>
        <p><span class="text-muted">Исполнитель:</span> {{ data.user_contractor?.email || 'Не назначен' }}</p>
        <p><span class="text-muted">Автор:</span> {{ data.user?.email || 'Не указан' }}</p>

        <h5>Договор</h5>
        <p>{{ data.contract?.title || 'Не привязан' }}</p>

        <h5>Даты</h5>
        <p><span class="text-muted">Создано:</span> {{ formatDate(data.created_at) }}</p>
        <p><span class="text-muted">Обновлено:</span> {{ formatDate(data.updated_at) }}</p>

        <h5>Время</h5>
        <p><span class="text-muted">Оценка времени выполнения, ч:</span> {{ data.estimated_amount }}</p>
        <p><span class="text-muted">Фактически затрачено, ч:</span> {{ data.time_spent }}</p>

        <h5>Стоимость</h5>
        <p><span class="text-muted">Стоимость по договору, руб.:</span> {{ formatPriceInt(data.contract_amount) }}</p>
        <p><span class="text-muted">Фактическая стоимость, руб.:</span> {{ formatPriceInt(data.actual_amount) }}</p>
        <p><span class="text-muted">Стоимость трудозатрат, руб.:</span> {{ formatPriceInt(data.cost_amount) }}</p>
      </div>
    </div>

    <HelpmateTabs>
      <HelpmateTab :title="`Комментарии (${comments.length})`" active="true">


        <div v-for="comment in comments" :key="comment.id">
          <div><span class="text-muted">{{ comment.user_object.first_name }} {{ comment.user_object.last_name }} - {{
            formatDateTime(comment.created_at) }}</span></div>
          <div class="bg-light" v-html="comment.content"></div>
        </div>

        <form @submit.prevent="submitComment">
          <ckeditor v-model="editorData" :editor="editor" :config="editorConfig" />
          <button class="btn btn-primary mt-1" type="submit">Добавить</button>
        </form>
      </HelpmateTab>
      <HelpmateTab title="Журнал работ (0)">

        <form class="mt-3" @submit.prevent="submitWorklog">
          <div class="form-group">
            <label for="worklogTime">Затраченное время, часы</label>
            <input type="number" class="form-control" v-model="worklogTime" placeholder="Затраченное время, часы" />
          </div>
          <div class="form-group">
            <label for="worklogDate">Дата затраченного времени</label>
            <input type="date" class="form-control" v-model="worklogDate" placeholder="Дата" />
          </div>
          <button class="btn btn-primary mt-1" type="submit">Добавить</button>
        </form>

        <div class="mt-3" v-for="worklog in worklogs" :key="worklog.id">
          <div><span class="text-muted">{{ worklog.user_object.first_name }} {{ worklog.user_object.last_name }} - {{
            formatDateTime(worklog.created_at) }}</span></div>
          <div class="bg-light">{{ formatDate(worklog.date_at) }} &rarr; {{ worklog.time_spent }} ч</div>
        </div>


      </HelpmateTab>
      <HelpmateTab title="История изменений (0)">2</HelpmateTab>
    </HelpmateTabs>


  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';
// import {Ckeditor} from '@ckeditor/ckeditor5-vue';
import HelpmateTab from "@/components/HelpmateTab.vue";
import HelpmateTabs from "@/components/HelpmateTabs.vue";
import { format } from 'date-fns';

const STATUS_NEW = 1;
const STATUS_IN_PROGRESS = 2;
const STATUS_COMPLETED = 3;
const STATUS_CLOSED = 4;
const STATUS_PAUSED = 5;
const STATUS_CANCELLED = 6;

export default {
  name: 'TaskDetailView',
  components: {
    NavBar,
    // Ckeditor,
    HelpmateTab,
    HelpmateTabs
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo],
        toolbar: ['undo', 'redo', '|', 'bold', 'italic'],
      },
      worklogTime: 8,
      worklogDate: moment().format('YYYY-MM-DD'),
      data: {
        code: '',  // Шифр Договора (титула)
        title: '',  // Наименование Договора (титула)
        user: null,  // Исполнитель
        content: '',  // Содержание задачи
        status: 0,
        status_text: '',
        date_at: '',
        created_at: null,
        updated_at: null,
        user_contractor: null,
        contract: null,
        available_transitions: [],
        contract_amount: 0,
        actual_amount: 0,
        cost_amount: 0,
        estimated_amount: 0,
      },
      comments: [],
      worklogs: [],
    };
  },
  methods: {
    submitWorklog() {
      WorklogDataService.create({
        task: this.taskId,
        time_spent: this.worklogTime,
        date_at: this.worklogDate
      })
        .then(() => {
          this.updateData()
        })
        .catch(e => {
          console.log(e);
        });
    },
    submitComment() {
      CommentDataService.create({
        task: this.taskId,
        content: this.editorData
      })
        .then(() => {
          this.editorData = ''
          this.updateComments()
        })
        .catch(e => {
          console.log(e);
        });
    },
    async reopenTask() {
      try {
        await TaskDataService.updateStatus(this.data.id, STATUS_NEW);
        this.updateData()
      } catch (error) {
        console.error("Ошибка при переоткрытии задачи:", error);
      }
    },
    async executeTask() {
      try {
        await TaskDataService.updateStatus(this.data.id, STATUS_IN_PROGRESS);
        this.updateData()
      } catch (error) {
        console.error("Ошибка при выполнении задачи:", error);
      }
    },
    async completeTask() {
      try {
        await TaskDataService.updateStatus(this.data.id, STATUS_COMPLETED);
        this.updateData()
      } catch (error) {
        console.error("Ошибка при завершении задачи:", error);
      }
    },
    async cancelTask() {
      try {
        await TaskDataService.updateStatus(this.data.id, STATUS_CANCELLED);
        this.updateData()
      } catch (error) {
        console.error("Ошибка при отмене задачи:", error);
      }
    },
    async closeTask() {
      try {
        await TaskDataService.updateStatus(this.data.id, STATUS_CLOSED);
        this.updateData()
      } catch (error) {
        console.error("Ошибка при закрытии задачи:", error);
      }
    },
    async pauseTask() {
      try {
        await TaskDataService.updateStatus(this.data.id, STATUS_PAUSED);
        this.updateData()
      } catch (error) {
        console.error("Ошибка при приостановке задачи:", error);
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      return format(new Date(dateString), 'dd.MM.yyyy HH:mm');
    },
    updateComments() {
      CommentDataService.getList({ task: this.taskId })
        .then((response) => {
          console.log('rrrrrrr', response);
          this.comments = response.data.results
        })
        .catch(e => {
          console.log(e);
        });
    },
    updateWorklogs() {
      WorklogDataService.getList({ task: this.taskId })
        .then((response) => {
          this.worklogs = response.data.results
        })
        .catch(e => {
          console.log(e);
        });
    },
    updateData() {
      this.isLoading = true
      // console.log('this.contractId', this.contractId)
      TaskDataService.getById(this.taskId)
        .then((response) => {
          console.log(response);
          this.data = { ...response.data }
          // this.data.user_autocomplete_object = { id: this.data.user, title: this.data.user_object.email }
          // Object.keys(this.data).forEach((key) => {
          //   this.data[key] = formatPriceInt(this.data[key]);
          // });
          this.updateComments()
          this.updateWorklogs()
          this.isLoading = false
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
  computed: {
    taskId() {
      return Number(this.$route.params.id);
    }
  },
  mounted() {
    this.updateData()
  },
};
</script>

<style>
.ck-editor__editable_inline {
  min-height: 100px;
}
</style>