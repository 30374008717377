<template>
    <main class="d-flex flex-nowrap" v-if="isAuthenticated">

      <SidebarMenu></SidebarMenu>

      <div class="scrollarea">
        <router-view></router-view>
      </div>

    </main>

  <div v-if="!isAuthenticated">
    <LoginView></LoginView>
  </div>

  <HelpmateToasts></HelpmateToasts>
</template>
<script>
import SidebarMenu from "@/components/SidebarMenu.vue";
import LoginView from "@/views/LoginView.vue";
import UserDataService from "@/services/UserDataService";
import HelpmateToasts from "@/components/HelpmateToasts.vue";

export default {
  name: 'UserListComponent',
  components: {HelpmateToasts, LoginView, SidebarMenu},
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated; // Получаем состояние из хранилища
    },
  },
  mounted() {
    UserDataService.getProfile()
        .then(response => {
          // Если данные получены, выставляем профиль пользователя
          this.$store.commit('setProfile', response.data)
        })
        .catch(() => {
          // Если получили ошибку при попытке смонтировать, то выкидываем на страницу логина
          this.$store.commit('removeUserToken')
          this.$router.push("/login");
        });
  }
  // data() {
  //   return {
  //     isAuthenticated: localStorage.getItem("userToken") != null,
  //   };
  // },
  // methods: {
  //   getObjects() {
  //     UserDataService.getAll()
  //         .then(response => {
  //           this.objects = response.data.results;
  //           console.log(response.data);
  //           this.isLoading = false;
  //         })
  //         .catch(e => {
  //           console.log(e);
  //         });
  //   },
  // },
  // mounted() {
  //   this.getObjects();
  // }
};
</script>