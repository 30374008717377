<script setup>
import UserDataService from "@/services/UserDataService";
import TaskListComponent from "@/components/Tasks/Task/TaskListComponent.vue";
</script>

<template>
  <NavBar :title="'Задачи'" :breadcrumbs="[{'url': '/users', 'title': 'Сотрудники'}, {'url': `/users/detail/${userContractorId}`, 'title': `${data.first_name} ${data.last_name}`}]"></NavBar>
  <div>

    <h5>Сотрудник: {{ data.first_name }} {{ data.last_name }}</h5>
    <h5>Email: {{ data.email }}</h5>

    <TaskListComponent :tableContext="tableContext"></TaskListComponent>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'UserTaskListView',
  components: {NavBar, TaskListComponent},
  data() {
    return {
      tableContext: {
        userContractorId: Number(this.$route.params.id),
        baseFilter: {
          user_contractor_id: Number(this.$route.params.id),
        }
      },
      data: {
        id: 0,
        first_name: '',
        last_name: '',
        email: '',
      },
      isLoading: false,
    }
  },
  methods: {
    updateData() {
      this.isLoading = true
      // console.log('this.contractId', this.contractId)
      UserDataService.getById(this.userContractorId)
        .then((response) => {
          this.data = { ...response.data }
          this.isLoading = false
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
  computed: {
    userContractorId() {
      return this.$route.params.id; // Получаем ID из параметров маршрута
    }
  },
  mounted() {
    this.tableContext.userContractorId = this.userContractorId
    this.tableContext.baseFilter.user_contractor_id = this.userContractorId
    this.updateData()
  }
};
</script>
