<template>
  <div>
<!--    <label for="view-select">Выберите вид разбивки:</label>-->
<!--    <select id="view-select" v-model="selectedView" @change="updateDays">-->
<!--      <option value="day">День</option>-->
<!--      <option value="week">Неделя</option>-->
<!--      <option value="month">Месяц</option>-->
<!--      <option value="year">Год</option>-->
<!--    </select>-->
    <input v-model="filterResource">
  </div>
  <div class="timeline-container">
    <table class="table table-bordered timeline-table" style="width: 100%;">
      <thead>
      <tr>
        <th class="fixed-column"></th>
        <th v-for="day in days" :key="day" class="date-cell">{{ day }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="resource in resources" :key="resource">
        <td class="fixed-column">{{ resource }}</td>
        <td style="padding: 0;" v-for="day in days" :key="day" class="event-cell position-relative">
          <div
              v-for="event in getEventsForDay(resource, day)"
              :key="event.id"
              class="event"
              :style="{
                width: '100%',
                height: '100%',
                backgroundColor: getEventStyle(event).backgroundColor,
                position: 'absolute',
                borderRadius: '0'
              }"
          ></div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'HelpmateTimeline',
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedView: 'day', // По умолчанию выбран день
      filterResource: '', // По умолчанию не фильтруем
    };
  },
  computed: {
    days() {
      const startDate = new Date(Math.min(...this.events.map(event => new Date(event.start_date))));
      const endDate = new Date(Math.max(...this.events.map(event => new Date(event.end_date))));
      const dayCount = this.calculateDayCount(startDate, endDate);

      return Array.from({length: dayCount}, (_, i) => {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i * this.getMultiplier());
        return date.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'});
      });
    },
    resources() {
      // return [...new Set(this.events.map(event => event.resource))];
      return [...new Set(
          this.events
              .filter(event => event.resource.toLowerCase().includes(this.filterResource.toLowerCase())) // Фильтрация по вхождению
              .map(event => event.resource)
      )]
    },
  },
  methods: {
    getMultiplier() {
      switch (this.selectedView) {
        case 'week':
          return 7;
        case 'month':
          return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(); // Количество дней в месяце
        case 'year':
          return 365; // Упрощенно, не учитывает високосные годы
        default:
          return 1; // По умолчанию - день
      }
    },
    calculateDayCount(startDate, endDate) {
      const duration = endDate - startDate;
      switch (this.selectedView) {
        case 'week':
          return Math.ceil(duration / (1000 * 60 * 60 * 24 * 7)) + 1;
        case 'month':
          return Math.ceil(duration / (1000 * 60 * 60 * 24 * 30)); // Упрощенно
        case 'year':
          return Math.ceil(duration / (1000 * 60 * 60 * 24 * 365)); // Упрощенно
        default:
          return Math.ceil(duration / (1000 * 60 * 60 * 24)) + 2; // По умолчанию - день
      }
    },
    updateDays() {
      // Обновление дней при изменении вида разбивки
      this.days; // Триггерим пересчет дней
    },
    getEventsForDay(resource, day) {
      const date = new Date(day.split('.').reverse().join('-'));
      return this.events.filter(event =>
          event.resource === resource &&
          new Date(event.start_date) <= date &&
          new Date(event.end_date) >= date
      );
    },
    getEventStyle(event) {
      const startDate = new Date(event.start_date);
      const endDate = new Date(event.end_date);
      const duration = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1; // Количество дней события
      const leftOffset = (new Date(startDate).getTime() - new Date(this.days[0].split('.').reverse().join('-')).getTime()) / (1000 * 60 * 60 * 24);

      return {
        width: `${(duration / this.days.length) * 100}%`,
        left: `${(leftOffset / this.days.length) * 100}%`,
        position: 'absolute',
        backgroundColor: 'blue',
        color: 'white',
        padding: '2px',
        borderRadius: '4px',
      };
    },
  },
};
</script>

<style scoped>
.timeline-container {
  max-height: 100%; /* Установите нужную высоту */
  /*max-width: 1000px;*/
  width: 100%;
  overflow-x: auto; /* Включаем вертикальную прокрутку */
}

.timeline-table {
  border-collapse: collapse;
  width: 100%;
}

.fixed-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}

.date-cell {
  text-align: center;
}

.event-cell {
  position: relative;
  height: 40px; /* Высота ячейки для событий */
}

.event {
  text-align: center;
}
</style>
