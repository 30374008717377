<template>
  <NavBar :title="'Договоры'">
    <RouterLink class="btn btn-outline-success" to="/contracts/create">+</RouterLink>
  </NavBar>

  <div class="container">
    <ContractListComponent></ContractListComponent>
  </div>
</template>

<script>

import NavBar from "@/components/NavBar.vue";
import ContractListComponent from "@/components/LegalDocuments/Contract/ContractListComponent.vue";

export default {
  name: 'ContractListView',
  components: {
    NavBar,
    ContractListComponent
  },
};
</script>
