<template>
  <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" v-if="visible">
    <div class="toast-header">
      <strong class="me-auto">{{ title }}</strong>
<!--      <small>Только что</small>-->
      <button type="button" class="btn-close" @click="hideToast" aria-label="Закрыть"></button>
    </div>
    <div class="toast-body">
      {{ message }}
    </div>
  </div>

</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Уведомление'
    },
    message: {
      type: String,
      default: 'Это ваше уведомление!'
    },
    duration: {
      type: Number,
      default: 3000 // 3 секунды
    }
  },
  data() {
    return {
      visible: false
    };
  },
  mounted() {
    this.visible = true;
    setTimeout(this.hideToast, this.duration);
  },
  methods: {
    showToast() {
      this.visible = true;
      setTimeout(this.hideToast, this.duration);
    },
    hideToast() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
.toast {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
</style>
