<template>
  <NavBar :title="'Акты'"></NavBar>
<!--    <RouterLink to="/acts/">Новый</RouterLink>-->
  <ActListComponent></ActListComponent>
</template>

<script>

import NavBar from "@/components/NavBar.vue";
import ActListComponent from "@/components/LegalDocuments/Act/ActListComponent.vue";

export default {
  name: 'ActListView',
  components: {
    NavBar,
    ActListComponent,
  },
};
</script>
