<template>
  <NavBar :title="'Настройки доступа'"></NavBar>
<!--    <RouterLink to="/acts/">Новый</RouterLink>-->


  <table class="table">
    <thead>
    <tr>
      <th>Наименование доступа</th>
      <th>Сотрудник</th>
      <th>РП</th>
      <th>Руководитель</th>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td>Задачи</td>
        <td>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Просмотр всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Создание</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Изменение всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Удаление всех</label>
        </td>
        <td>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Просмотр всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Создание</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Изменение всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Удаление всех</label>
        </td>
        <td>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Просмотр всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Создание</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Изменение всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Удаление всех</label>
        </td>
      </tr>
      <tr>
        <td>Договоры</td>
        <td>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Просмотр всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Создание</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Изменение всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Удаление всех</label>
        </td>
        <td>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Просмотр всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Создание</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Изменение всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Удаление всех</label>
        </td>
        <td>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Просмотр всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Создание</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Изменение всех</label><br/>
          <label class="form-check-label"><input type="checkbox" class="form-check-input"> Удаление всех</label>
        </td>
      </tr>
    </tbody>
  </table>

  <button class="btn btn-warning">Сбросить права (выставить "по умолчанию")</button>

</template>

<script>

import NavBar from "@/components/NavBar.vue";

export default {
  name: 'SettingsACLView',
  components: {NavBar},
};
</script>
