<template>
  <NavBar :title="'Профиль'"></NavBar>

  <HelpmateSpinner :is-loading="isLoading"></HelpmateSpinner>

  <div class="container" v-if="!isLoading">
    <div class="row">
      <div class="col-md-6">
        <form @submit.prevent="updateProfile">
          <div class="form-group">
            <label for="last_name">Логин</label>
            <input type="text" class="form-control" v-model="profileObject.username" disabled id="last_name">
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="last_name">Фамилия</label>
                <input type="text" class="form-control" v-model="profileObject.last_name" id="last_name">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="first_name">Имя</label>
                <input type="text" class="form-control" v-model="profileObject.first_name" id="first_name">
              </div>
            </div>
          </div>


          <button type="submit" class="btn btn-primary mt-3">Сохранить</button>

          <div class="mt-3">
            <!--            <RouterLink :to="{'url': { name: 'ProfileChangePasswordView'}}">Сменить пароль</RouterLink>-->
            <RouterLink to="/profile/change-password">Сменить пароль</RouterLink>
          </div>
        </form>

      </div>
    </div>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import UserDataService from '@/services/UserDataService';
import HelpmateSpinner from "@/helpmate/components/HelpmateSpinner.vue";

export default {
  name: 'ProfileView',
  components: {HelpmateSpinner, NavBar},
  data() {
    return {
      isLoading: true,
      profileObject: {
        first_name: '',
        last_name: '',
        username: '',
      },
    };
  },
  methods: {
    getObject() {
      UserDataService.getProfile()
          .then(response => {
            console.log(response.data);
            this.profileObject = response.data;
            this.isLoading = false;
          })
          .catch(e => {
            console.log(e);
          });
    },
    updateProfile() {


      UserDataService.updateProfile({
        first_name: this.profileObject.first_name,
        last_name: this.profileObject.last_name,
      })
          .then(response => {
            console.log(response.data)
            this.profileObject = response.data
            this.$store.commit('setProfile', {...response.data})
            this.$store.commit('addToast', {title: "Сохранено", message:"Профиль сохранен"})
          })
          .catch(e => {
            console.log(e);
            this.$store.commit('addToast', {title: "Ошибка!", message:"Профиль не сохранен"})
          });
    },
  },
  mounted() {
    this.getObject();
  }
};
</script>
