<script setup>
import UserDataService from "@/services/UserDataService";
import HelpmateAutocomplete from "@/helpmate/components/HelpmateAutocomplete.vue";
</script>

<template>
  <NavBar :title="`Договор ${data.code}`"
    :breadcrumbs="[{ 'url': { name: 'ContractListView', params: {} }, 'title': 'Договоры' }]">

  </NavBar>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h2>Договор</h2>
        <form @submit.prevent="updateContract">
          <div class="form-group">
            <label for="contractCode">Шифр Договора (титула)</label>
            <input type="text" v-model="data.code" class="form-control" id="contractCode">
          </div>
          <div class="form-group">
            <label for="contractTitle">Наименование Договора (титула)</label>
            <input type="text" v-model="data.title" class="form-control" id="contractTitle">
          </div>
          <div class="form-group">
            <label for="contractAmount">Стоимость Договора</label>
            <input type="number" v-model="data.amount" class="form-control" id="contractAmount">
          </div>

          <div class="form-group">
            <label for="contractUser">Руководитель проекта</label>
            <HelpmateAutocomplete
                :autofocus="true"
                 @onselect="handleUserSelect"
                :id="`contractUser`"
                :value="data.user_autocomplete_object"
                :dataService="UserDataService"
            ></HelpmateAutocomplete>
          </div>
          <div class="form-group">
            <label for="contractEndAt">Дата окончания</label>
            <input type="date" v-model="data.end_at" class="form-control" id="contractEndAt">
          </div>

          <button type="submit" class="btn btn-primary mt-3">Сохранить</button>
          &nbsp;
          <RouterLink class="btn btn-secondary mt-3" :to="`/contracts/${contractId}/tasks`">Задачи
            договора &rarr;</RouterLink>
        </form>
      </div>
      <div class="col-md-8">
        <div class="bg-light p-3 mt-3">
          <HelpmateTabs>
            <HelpmateTab title="Основные данные" active="true">
              <ContractTotalBase :contractId="contractId"></ContractTotalBase>
            </HelpmateTab>
            <HelpmateTab title="Расчет фактической стоимости">
              <p>Трудозатраты с налогами, без накладных и прибыли</p>
              <ContractTotalFact :contractId="contractId"></ContractTotalFact>
            </HelpmateTab>
            <HelpmateTab title="Расчет требуемой стоимости">
              <p>Трудозатраты с налогами, накладными и прибылью</p>
              <ContractTotalTreb :contractId="contractId"></ContractTotalTreb>
            </HelpmateTab>
          </HelpmateTabs>
        </div>
      </div>
    </div>





    <!-- <h1>Части договора</h1> -->

    <h5>Доп. соглашения по договору</h5>

    <SupplementaryAgreementListComponent :contractId="contractId"></SupplementaryAgreementListComponent>


    <h5>Субподряды</h5>

    <SubcontractListComponent :contractId="contractId"></SubcontractListComponent>

    <h5>Дополнительные расходы</h5>

    <AdditionalCostListComponent :contractId="contractId"></AdditionalCostListComponent>

    <h5>Командировочные расходы</h5>

    <TravelExpenseListComponent :contractId="contractId"></TravelExpenseListComponent>

    <h5>Акты</h5>

    <ActListComponent :contractId="contractId"></ActListComponent>

    <h5>Платежи</h5>

    <PaymentListComponent :contractId="contractId"></PaymentListComponent>

    <table class="table">
      <thead>
        <tr>
          <th>№ акта</th>
          <th>Назначение акта</th>
          <th>Кол-во комплектов</th>
          <th>Стоимость вып-ных раб., вкл. НДС, руб.</th>
          <th>Зачет аванса от ст-ти вып. раб., вкл. НДС</th>
          <th>% аванса</th>
          <th>К оплате с НДС, руб.</th>
          <th>% от ст-ти вып. работ</th>
          <th>Удерж. от ст-ти вып. раб. с НДС, руб.</th>
          <th>% удержания</th>
          <th>Примечание</th>
          <th>Дата акта</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td></td>
          <td></td>
          <td>10 000</td>
          <td>= % аванса * ст. вып. раб., вкл. НДС</td>
          <td></td>
          <td>= ст. вып. раб., вкл. НДС – зачет аванса – удерж. от ст. вып. раб.</td>
          <td>= ст. вып. раб., вкл НДС / к оплате</td>
          <td>= ст. вып. раб., вкл. НДС * % удержания</td>
          <td></td>
          <td></td>
          <td>11.09.2024</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>Итого</th>
          <th></th>
          <th></th>
          <th>10 000</th>
          <th>10 000</th>
          <th></th>
          <th>10 000</th>
          <th></th>
          <th>10 000</th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import SupplementaryAgreementListComponent
  from "@/components/LegalDocuments/SupplementaryAgreement/SupplementaryAgreementListComponent.vue";
import SubcontractListComponent from "@/components/LegalDocuments/Subcontract/SubcontractListComponent.vue";
import AdditionalCostListComponent from "@/components/LegalDocuments/AdditionalCost/AdditionalCostListComponent.vue";
import TravelExpenseListComponent from "@/components/TravelExpense/TravelExpenseListComponent.vue";
import ActListComponent from "@/components/LegalDocuments/Act/ActListComponent.vue";
import PaymentListComponent from "@/components/LegalDocuments/Payment/PaymentListComponent.vue";
import ContractTotalFact from "@/components/ContractTotal/ContractTotalFact.vue";
import ContractTotalTreb from "@/components/ContractTotal/ContractTotalTreb.vue";
import ContractTotalBase from "@/components/ContractTotal/ContractTotalBase.vue";
import ContractDataService from "@/services/ContractDataService";
import HelpmateTab from "@/components/HelpmateTab.vue";
import HelpmateTabs from "@/components/HelpmateTabs.vue";

export default {
  name: 'ContractDetailView',
  components: {
    ContractTotalTreb,
    ContractTotalFact,
    ContractTotalBase,
    PaymentListComponent,
    ActListComponent,
    TravelExpenseListComponent,
    AdditionalCostListComponent, SubcontractListComponent, SupplementaryAgreementListComponent, NavBar,
    HelpmateTab,
    HelpmateTabs
  },
  data() {
    return {
      isLoading: true,
      data: {
        code: '',  // Шифр Договора (титула)
        title: '',  // Наименование Договора (титула)
        amount: 0,  // Стоимость Договора
        user: null,  // Руководитель проекта
        end_at: null,  // Дата окончания
        user_autocomplete_object: { id: null, title: '' }
      }
    }
  },
  methods: {
    updateData() {
      this.isLoading = true
      // console.log('this.contractId', this.contractId)
      ContractDataService.getById(this.contractId)
        .then((response) => {
          console.log(response);
          this.data = { ...response.data }
          this.data.user_autocomplete_object = { id: this.data.user, title: this.data.user_object.email }
          // Object.keys(this.data).forEach((key) => {
          //   this.data[key] = formatPriceInt(this.data[key]);
          // });
          this.isLoading = false
        })
        .catch(e => {
          console.log(e);
        });
    },
    updateContract() {
      ContractDataService.patch(this.contractId, {
        code: this.data.code,
        title: this.data.title,
        amount: this.data.amount,
        user: this.data.user,
        user_object: this.data.user_object,
        end_at: this.data.end_at,
      })
        .then(response => {
          // console.log(response.data)
          this.data = response.data
          this.data.user_autocomplete_object = { id: this.data.user, title: this.data.user_object.email }
          this.$store.commit('addToast', { title: "Сохранено", message: "Данные договора сохранены" })
        })
        .catch(e => {
          console.log(e);
          this.$store.commit('addToast', { title: "Ошибка!", message: "Не сохранено" })
        });
    },
    handleUserSelect(option) {
      this.data.user = option.id
    }
  },
  computed: {
    contractId() {
      return Number(this.$route.params.id);
    }
  },
  mounted() {
    this.updateData()
  },
};
</script>
