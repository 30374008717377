<template>
  <div class="d-flex align-items-center py-4 bg-body-tertiary">
    <main class="form-signin w-100 m-auto">
      <form method="post">
        <h1 class="h3 mb-3 fw-normal">Вход</h1>

        <div class="form-floating">
          <input type="text" name="username" v-model="username" class="form-control" id="floatingInput"
                 placeholder="Имя пользователя">
          <label for="floatingInput">Логин</label>
        </div>
        <div class="form-floating">
          <input type="password" name="password" v-model="password" class="form-control" id="floatingPassword"
                 placeholder="Пароль">
          <label for="floatingPassword">Пароль</label>
        </div>

        <button class="btn btn-primary w-100 py-2" type="submit" @click.stop.prevent="submit()">Войти</button>
      </form>
    </main>
  </div>
</template>

<script>

import AuthDataService from "@/services/AuthDataService";
import UserDataService from "@/services/UserDataService";

export default {
  name: 'LoginView',
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    submit() {
      //if you want to send any data into server before redirection then you can do it here
      AuthDataService.login({
        username: this.username,
        password: this.password,
      })
          .then((response) => {
            this.$store.commit('setUserToken', response.data.token)

            // Получаем данные пользователя
            UserDataService.getProfile()
                .then(response => {
                  // Если данные получены, выставляем профиль пользователя
                  this.$store.commit('setProfile', response.data)
                })
                .catch(() => {});

            this.$router.push("/");
            // this.objects = response.data.results;
            console.log(response.data);
            // this.isLoading = false;
          })
          .catch(e => {
            console.log(e);
          });
    }
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>