<template>
  <NavBar :title="'Смена пароля'"
          :breadcrumbs="[{'url': { name: 'ProfileView', params: {} }, 'title': 'Профиль'}]"></NavBar>

  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <form @submit.prevent="updatePassword">
          <div class="form-group">
            <label for="password">Пароль</label>
            <input type="password" class="form-control"
                   id="password"
                   v-model="passwordObject.password">
          </div>
          <div class="form-group">
            <label for="password_repeat">Повторите пароль</label>
            <input type="password" class="form-control"
                   v-bind:class="{'is-invalid': passwordObject.password !== passwordObject.password_repeat}"
                   id="password_repeat" v-model="passwordObject.password_repeat">
            <small id="passwordHelp" class="text-danger" v-if="passwordObject.password !== passwordObject.password_repeat">
              Введенные пароли не совпадают.
            </small>
          </div>

          <button type="submit" class="btn btn-primary mt-3">Сменить пароль</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import UserDataService from "@/services/UserDataService";

export default {
  name: 'ProfileChangePasswordView',
  components: {NavBar},
  data() {
    return {
      passwordObject: {
        password: '',
        password_repeat: '',
      },
    };
  },
  methods: {
    updatePassword() {
      if (this.passwordObject.password.length === 0 || this.passwordObject.password !== this.passwordObject.password_repeat) {
        this.$store.commit('addToast', {title: "Ошибка!", message: "Пожалуйста, введите корректные данные."})
      } else {
        UserDataService.updateProfile({
          password: this.passwordObject.password,
        })
            .then(response => {
              console.log(response.data)
              this.$store.commit('addToast', {title: "Смена пароля", message: "Пароль изменен"})
            })
            .catch(e => {
              console.log(e);
              this.$store.commit('addToast', {
                title: "Ошибка!",
                message: "Произошла ошибка. Пожалуйста, повторите еще раз или свяжитесь с администратором."
              })
            });
      }
    },
  },
};
</script>
