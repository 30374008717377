import {formatDate, formatDateTime, formatLink, formatPrice, formatPriceInt, formatTimeAgo} from "@/helpmate/utils";

const formatField = (value, column={}) => {
    const fieldType = column.fieldType
    let formattedValue = ''
    let titleValue = ''
    let cssClasses = ''
    let linkData = {}
    if (fieldType === 'text') {
        formattedValue = value
    } else if (fieldType === 'date') {
        formattedValue = formatDate(value)
    } else if (fieldType === 'datetime') {
        formattedValue = formatDateTime(value)
    } else if (fieldType === 'timeago') {
        formattedValue = formatTimeAgo(value)
        titleValue = formatDateTime(value)
    } else if (fieldType === 'price') {
        formattedValue = formatPrice(value)
        cssClasses = 'helpmate-cell-number'
    } else if (fieldType === 'priceint') {
        formattedValue = formatPriceInt(value)
        cssClasses = 'helpmate-cell-number'
    } else if (fieldType === 'link') {
        formattedValue = formatLink(value, column)
        linkData = column.linkTemplate.replace('#ID#', value)
        cssClasses = ''
    } else if (fieldType === 'fk') {
        if (value) {
            formattedValue = value[column.fkFieldTitle] ? value[column.fkFieldTitle] : value.title
        } else {
            formattedValue = '-'
        }
        cssClasses = ''
    } else {
        formattedValue = '- Неизвестный тип поля -'
    }
    return {
        formattedValue,
        titleValue,
        cssClasses,
        linkData,
    }
}

export default formatField;