import { createStore } from 'vuex'
import httpCommon from "@/helpmate/services/http-common";

const initialUserToken = localStorage.getItem('userToken') || '';
console.log('initialUserToken', initialUserToken);
if (initialUserToken) {
  httpCommon.defaults.headers['Authorization'] = `Token ${initialUserToken}`
}

// Create a new store instance.
const store = createStore({
  state () {
    return {
      isAuthenticated: !!localStorage.getItem('isAuthenticated') || false, // начальное состояние
      userToken: initialUserToken, // начальное состояние
      profile: {
        last_name: '',
        first_name: '',
      }, // данные профиля пользователя
      toasts: [],
      // contractTotalFact: {},
      // contractTotalTreb: {},
      // needUpdateContractTotalFact: false,
      // needUpdateContractTotalTreb: false,
    }
  },
  mutations: {
    setUserToken(state, userToken) {
      state.userToken = userToken;
      state.isAuthenticated = true;
      localStorage.setItem('userToken', userToken)
      localStorage.setItem('isAuthenticated', 'true')
      httpCommon.defaults.headers['Authorization'] = `Token ${store.state.userToken}`
    },
    removeUserToken(state) {
      state.userToken = '';
      state.isAuthenticated = false;
      localStorage.removeItem('userToken')
      localStorage.removeItem('isAuthenticated')
      delete httpCommon.defaults.headers['Authorization']
    },
    setProfile(state, profileData) {
      state.profile = {...profileData}
    },
    addToast(state, toastData) {
      state.toasts.push(toastData)
    },
    // setNeedUpdateContractTotalFact(state, value) {
    //   state.needUpdateContractTotalFact = value
    // },
    // setNeedUpdateContractTotalTreb(state, value) {
    //   state.needUpdateContractTotalTreb = value
    // }
  }
})

// const store = new Vuex.Store({
//   state: {
//     isAuthenticated: false, // начальное состояние
//   },
//   mutations: {
//     setAuthentication(state, status) {
//       state.isAuthenticated = status;
//     },
//   },
//   actions: {
//     login({ commit }) {
//       // Здесь можно добавить логику аутентификации
//       commit('setAuthentication', true);
//     },
//     logout({ commit }) {
//       commit('setAuthentication', false);
//     },
//   },
//   getters: {
//     isAuthenticated: (state) => state.isAuthenticated,
//   },
// });

export default store;
