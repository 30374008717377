import http from "@/helpmate/services/http-common";


class AuthDataService {

  login(data) {
    return http.post("/login/", data);
  }

  logout() {
    return http.post(`/logout/`);
  }

}

export default new AuthDataService();
