<template>

  <HelpmateTable :dataService="getDataService()" :columns="columns"></HelpmateTable>

</template>

<script>
import ContractDataService from '@/services/ContractDataService';
import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";
import UserDataService from '@/services/UserDataService';

export default {
  name: 'ContractListComponent',
  components: {
    HelpmateTable,
  },
  data() {
    return {
      columns: [
        {field: 'id', fieldType: 'link', title: 'Подробнее', linkTemplate: '/contract/detail/#ID#', readonly: true},
        {field: 'code', fieldType: 'text', title: 'Шифр'},
        {field: 'title', fieldType: 'text', title: 'Наименование'},
        {field: 'user_object', fieldType: 'fk', title: 'Руководитель проекта', fkFieldTitle: 'email', fkSaveField: 'user', dataService: UserDataService},
        {field: 'end_at', fieldType: 'date', title: 'Дата окончания'},
        {field: 'remaining_amount', fieldType: 'priceint', title: 'Остаток по договору, руб.', readonly: true, aggregate: 'sum'},
        {field: 'id', fieldType: 'link', title: 'Задачи Договора', linkTemplate: '/contracts/#ID#/tasks', readonly: true},
      ],
    }
  },
  methods: {
    getDataService() {
      return ContractDataService
    },
  },
};
</script>


<!--<script>-->
<!--import ContractDataService from '@/services/ContractDataService';-->
<!--import HelpmateSpinner from "@/helpmate/components/HelpmateSpinner.vue";-->
<!--import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";-->

<!--export default {-->
<!--  name: 'ContractListComponent',-->
<!--  components: {HelpmateTable},-->
<!--  computed: {-->
<!--    totalAmount: function () {-->
<!--      let sum = 0;-->
<!--      this.objects.forEach(e => {-->
<!--        sum += Number(e.amount);-->
<!--      });-->
<!--      return sum-->
<!--    }-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      isLoading: true,-->
<!--      objects: [],-->
<!--    };-->
<!--  },-->
<!--  methods: {-->
<!--    getObjects() {-->
<!--      ContractDataService.getAll()-->
<!--          .then(response => {-->
<!--            this.objects = response.data.results;-->
<!--            console.log(response.data);-->
<!--            this.isLoading = false;-->
<!--          })-->
<!--          .catch(e => {-->
<!--            console.log(e);-->
<!--          });-->
<!--    },-->
<!--  },-->
<!--  mounted() {-->
<!--    this.getObjects();-->
<!--  }-->
<!--};-->
<!--</script>-->
