<template>
  <NavBar :title="'Новая задача'"></NavBar>
  <div>
    <h1>Задача - создание</h1>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'TaskCreateView',
  components: {NavBar},
};
</script>
