<template>
  <NavBar :title="'Настройки'"></NavBar>
    <RouterLink to="/settings/acl">ACL</RouterLink>
</template>

<script>

import NavBar from "@/components/NavBar.vue";

export default {
  name: 'SettingsView',
  components: {NavBar},
};
</script>
