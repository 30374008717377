<script setup>
import UserDataService from "@/services/UserDataService";
</script>

<template>
  <NavBar :title="`${data.last_name} ${data.first_name}`"
    :breadcrumbs="[{ 'url': { name: 'UserListView', params: {} }, 'title': 'Сотрудники' }]">
  </NavBar>
  <div class="container">

    <div class="row">
      <div class="col-md-8">
        <h5>Детали</h5>
        <p><span class="text-muted">Email:</span> {{ data.email }}</p>
        <p><span class="text-muted">Должность:</span> {{ data.staff_position_title }}</p>
        <p><span class="text-muted">Дата регистрации:</span> {{ data.date_joined }}</p>
        <p><span class="text-muted">Последняя активность:</span> {{ data.last_login }}</p>
        <p><span class="text-muted">Зарплата, руб./мес.:</span> {{ data.salary_monthly_amount }}</p>
        <p><span class="text-muted">Стоимость часа в текущем месяце, руб.:</span> {{ data.salary_hourly_amount }}</p>
      </div>
    </div>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { formatDate, formatTimeAgo, formatPriceInt, formatPrice } from "@/helpmate/utils";

export default {
  name: 'UserDetailView',
  components: {
    NavBar,
  },
  data() {
    return {
      data: {
        last_name: '',
        first_name: '',
        email: '',
        date_joined: '',
        last_login: '',
        staff_position_title: '',
        salary_monthly_amount: null,
        salary_hourly_amount: null,
      },
    };
  },
  methods: {
    updateData() {
      UserDataService.getById(this.userId)
        .then((response) => {
          console.log(response);
          this.data = { ...response.data }
          this.data.date_joined = formatDate(this.data.date_joined);
          this.data.last_login = formatTimeAgo(this.data.last_login);
          this.data.salary_monthly_amount = formatPriceInt(this.data.salary_monthly_amount);
          this.data.salary_hourly_amount = formatPrice(this.data.salary_hourly_amount);
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
  computed: {
    userId() {
      return Number(this.$route.params.id);
    }
  },
  mounted() {
    this.updateData()
  },
};
</script>