<template>
  <div class="d-flex justify-content-center" v-if="isLoading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpmateSpinner',
  props: ['isLoading'],
}
</script>