<template>
  <HelpmateSpinner :is-loading="isLoading"></HelpmateSpinner>
</template>

<script>

import AuthDataService from "@/services/AuthDataService";
import HelpmateSpinner from "@/helpmate/components/HelpmateSpinner.vue";

export default {
  name: 'LogoutView',
  components: {HelpmateSpinner},
  data() {
    return {
      isLoading: true,
    }
  },
  mounted() {
    AuthDataService.logout()
        .then(() => {
          this.isLoading = false;
          this.$store.commit('removeUserToken')
          this.$store.commit('setProfile', {
            last_name: '',
            first_name: '',
          })
          this.$router.push("/login");
        })
        .catch(e => {
          console.log(e);
        });
  }
};
</script>
