import http from "@/helpmate/services/http-common";


class ContractTotalTrebDataService {

    getData({contractId}) {
        return http.get(`/total_cash_treb/`, {params: {
            contract_id: contractId,
        }});
    }

}

export default new ContractTotalTrebDataService();
