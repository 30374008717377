<template>
    <NavBar :title="'Орг структура'"></NavBar>

    <OrgChart :data="data"></OrgChart>
</template>

<script>
import OrgChart from "@/components/OrgChart.vue";
import NavBar from "@/components/NavBar.vue";
// import * as d3 from 'd3';

export default {
  name: 'OrgStructureView',
  components: {
    NavBar,
    OrgChart
  },
  data() {
    return {
      data: []
    };
  },
  created() {
    let promise = new Promise((resolve) => {
      resolve("result");
    });

    promise
        .then(
            () => {
              this.data = [
                {'id': 1, 'parentId': null, 'name': 'node1', 'title': 'Генеральный директор'},
                {'id': 2, 'parentId': 1, 'name': 'node2', 'title': 'Зам. генерального директора'},
                {'id': 3, 'parentId': 1, 'name': 'node3', 'title': 'Главный бухгалтер'},
                {'id': 4, 'parentId': 3, 'name': 'node4', 'title': 'Бухгалтер'},
                {'id': 5, 'parentId': 2, 'name': 'node4', 'title': 'Исполнитель'},
              ];
            },
            // eslint-disable-next-line
            () => {
              this.data = [];
              // вторая функция - запустится при вызове reject
              // alert("Rejected: " + error); // error - аргумент reject
            }
        );

    // console.log(this.data);
    // d3.csv(
    //     'https://raw.githubusercontent.com/bumbeishvili/sample-data/main/org.csv'
    // ).then(d => {
    //   console.log('fetched data', d);
    //   this.data = d;
    //   console.log(JSON.stringify(this.data));
    // });
  }
};
</script>
